import { apiUsers } from '@/api/users';
import {
  UpdateEmailPreferences,
  UpdatePassword,
  User,
  UserRegistration,
  UserRegistrationResponse,
  UserState,
} from '@/typings/user';
import axios, { AxiosError } from 'axios';
import { defineStore } from 'pinia';
import { removeCookie, setCookie } from 'tiny-cookie';

export const useUserStore = defineStore({
  id: 'storeUser',
  state: (): UserState => ({
    user: null,
    secureUser: null,
    viewUser: null,
    token: '',
    validationError: '',
  }),
  actions: {
    async getLoggedInUser() {
      try {
        const response = await apiUsers.getUser();
        if (response.data.verified) {
          this.user = response.data.user;
          this.validationError = '';
        } else {
          this.user = null;
          this.validationError = '';
        }
      } catch (error) {
        console.error(error);
        this.user = null;
        this.validationError = '';
      }
    },
    async getSecureUser() {
      try {
        const response = await apiUsers.getSecureUser();
        this.secureUser = response.data;
        this.validationError = '';
      } catch (error) {
        console.error(error);
        this.user = null;
        this.validationError = '';
      }
    },
    async login(email: string, password: string) {
      try {
        if (!email.includes('@')) {
          this.validationError = "Email must include an '@' symbol";
          return false;
        }

        if (!password.length) {
          this.validationError = 'Password must not be blank.';
          return false;
        }

        const response = await apiUsers.login(email, password);

        this.validationError = '';
        const now = new Date();
        now.setMonth(now.getMonth() + 1);

        this.token = response.data;

        setCookie('pws-jwt', response.data, {
          expires: now.toUTCString(),
          samesite: 'lax',
        });
        await this.getLoggedInUser();
        return true;
      } catch (error) {
        if (
          (error as AxiosError<unknown>).response?.status === 400 ||
          (error as AxiosError<unknown>).response?.status === 404
        ) {
          this.validationError = 'Incorrect email or password';
        } else {
          this.validationError = 'Something has gone wrong. Try again later.';
        }
        this.user = null;
      }
      return false;
    },
    async logout() {
      removeCookie('pws-jwt');
      this.validationError = '';
      this.user = null;
    },
    async getStatistics(id: string) {
      try {
        const response = await apiUsers.getStatistics(id);
        return response.data;
      } catch (error) {
        console.error(error);
        this.user = null;
      }
    },
    async changePassword(data: UpdatePassword) {
      if (data.new.trim().length >= 8 && data.new === data.confirm) {
        try {
          const response = await apiUsers.changePassword(data);
          if (response.data.success) {
            return true;
          }
        } catch (error) {
          console.error(error);
        }
      }
      return false;
    },
    async updateEmailPreferences(data: UpdateEmailPreferences) {
      if (this.secureUser) {
        const hasNoEmail = data.Email && data.Email.length === 0;
        const hasNoTwitter =
          data.TwitterHandle && data.TwitterHandle.length === 0;
        if (!hasNoEmail && !hasNoTwitter) {
          const response = await apiUsers.updateEmailPreferences(data);
          if (response.status === 200) {
            this.secureUser.Email = data.Email;
            this.secureUser.EmailPreference = data.EmailPreferences;
            return true;
          }
        }
      }
      return false;
    },
    async linkTwitterAccount() {
      const response = await apiUsers.linkTwitterAccount();
      return response.data;
    },
    async unlinkTwitterAccount() {
      if (
        this.secureUser &&
        this.secureUser.Email &&
        this.secureUser.Email.length > 0
      ) {
        const response = await apiUsers.unlinkTwitterAccount(
          this.secureUser.UserId
        );
        if (response.status === 200) {
          this.secureUser.TwitterHandle = '';
          return true;
        }
      }
      return false;
    },
    async linkTwitterAccountCallback(data: unknown) {
      const response = await apiUsers.linkTwitterAccountCallback(data);
      return response.data;
    },
    async loginTwitterCallback(data: unknown) {
      const response = await apiUsers.loginTwitterCallback(data);

      const now = new Date();
      now.setMonth(now.getMonth() + 1);
      setCookie('pws-jwt', response.data, {
        expires: now.toUTCString(),
        samesite: 'lax',
      });
      await this.getLoggedInUser();
      return true;
    },
    async signupTwitterCallback(data: unknown) {
      const response = await apiUsers.signupTwitterCallback(data);

      const now = new Date();
      now.setMonth(now.getMonth() + 1);
      setCookie('pws-jwt', response.data, {
        expires: now.toUTCString(),
        samesite: 'lax',
      });
      await this.getLoggedInUser();
      return true;
    },
    async resetPassword(email: string) {
      await apiUsers.forgetPassword(email);
    },
    async register(data: UserRegistration): Promise<UserRegistrationResponse> {
      try {
        const response = await apiUsers.registerNewUser(data);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          return axiosError.response?.data;
        } else {
          throw error;
        }
      }
    },
    async twitterLogin() {
      const response = await apiUsers.twitterLogin();
      return response.data;
    },
    async twitterSignup() {
      const response = await apiUsers.twitterSignup();
      return response.data;
    },
    async getUserAccount(userName: User['Name']) {
      try {
        const response = await apiUsers.getViewUser(userName);
        this.viewUser = response.data;
      } catch (error) {
        console.error(error);
        this.viewUser = null;
        this.validationError = '';
      }
    },
    async getUsers() {
      try {
        const response = await apiUsers.getUsers();
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
