<template>
  <img
    class="h-full logo"
    src="@/assets/images/prowess.svg"
    alt="Pro Wrestling Scorecards - Prowess"
  />
</template>

<style lang="postcss" scoped>
.logo {
  display: unset;
  vertical-align: unset;
  filter: invert(7%) sepia(88%) saturate(5593%) hue-rotate(1deg);
}
</style>
