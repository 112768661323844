<template>
  <div class="flex flex-col justify-center items-center gap-y-10">
    <div
      class="spinner animate-spin border-4 border-primary h-24 w-24 rounded-full"
    ></div>
    <div>
      <slot />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.spinner {
  border-bottom-color: transparent;
}
</style>
