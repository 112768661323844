import { apiFactions } from '@/api/faction';
import { FactionPayload } from '@/typings/api/factions';
import { Faction, FactionPrivacy, FactionState } from '@/typings/faction';
import { FactionUser } from '@/typings/user';
import { defineStore } from 'pinia';
import { useUserStore } from './users';

export const useFactionsStore = defineStore({
  id: 'storeFactions',
  state: (): FactionState => ({
    publicFactions: [],
    privateFactions: [],
    faction: null,
  }),
  getters: {
    factions(): Faction[] {
      return [...this.publicFactions, ...this.privateFactions];
    },
  },
  actions: {
    async addFaction(faction: FactionPayload) {
      await apiFactions.postFaction(faction);
    },
    async getFactions() {
      this.faction = null;
      try {
        let response = await apiFactions.getPublicFactions();
        this.publicFactions = response.data;
        const communityIndex = this.publicFactions.findIndex(
          (x) => x.GroupId === 18
        );
        const community = this.publicFactions.splice(communityIndex, 1)[0];
        this.publicFactions.unshift(community);

        response = await apiFactions.getPrivateFactions();
        this.privateFactions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getFaction(uuid: string) {
      try {
        const response = await apiFactions.getFactionByUuid(uuid);
        this.faction = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async putFaction(faction: Faction) {
      await apiFactions.putFaction(faction);
    },
    async getFactionRankedScorecards(uuid: string) {
      try {
        const response = await apiFactions.getRankedScorecards(uuid);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getFactionScorecards(uuid: string) {
      try {
        const response = await apiFactions.getScorecards(uuid);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getFactionScorecard(uuid: string, slug: string) {
      try {
        const response = await apiFactions.getScorecard(uuid, slug);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async joinPublic(faction: Faction): Promise<boolean> {
      const userStore = useUserStore();
      if (faction.Privacy === FactionPrivacy.Public && userStore.user) {
        try {
          await apiFactions.join(userStore.user, faction.JoinCode);
          faction.Joined = true;
          return true;
        } catch (error) {
          console.error(error);
        }
      }
      return false;
    },
    async joinInviteOnly(faction: Faction, code: string): Promise<boolean> {
      const userStore = useUserStore();
      if (faction.Privacy === FactionPrivacy.InviteOnly && userStore.user) {
        try {
          await apiFactions.join(userStore.user, code);
          faction.Joined = true;
          return true;
        } catch (error) {
          console.error(error);
        }
      }
      return false;
    },
    async joinPrivate(code: string): Promise<boolean> {
      const userStore = useUserStore();
      if (userStore.user) {
        try {
          await apiFactions.join(userStore.user, code);
          await this.getFactions();
          return true;
        } catch (error) {
          console.error(error);
        }
      }
      return false;
    },
    async leave(faction: Faction): Promise<boolean> {
      const userStore = useUserStore();
      if (userStore.user) {
        try {
          await apiFactions.leave(userStore.user, faction);
          faction.Joined = false;
          return true;
        } catch (error) {
          console.error(error);
        }
      }
      return false;
    },
    async getChampions() {
      if (this.faction) {
        try {
          const response = await apiFactions.getChampions(this.faction.Uuid);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      }
      return [];
    },
    async getMembers(pageSize?: number, page?: number) {
      if (this.faction) {
        try {
          const response = await apiFactions.getMembers(
            this.faction.Uuid,
            pageSize,
            page
          );
          if (response.data.Users) {
            return response.data.Users.filter(
              (user: FactionUser) => !user.FactionMember.IsFollower
            );
          }
          return response.data;
        } catch (error) {
          console.error(error);
        }
      }
      return [];
    },
  },
});
