import { apiScorecards } from '@/api/scorecard';
import { ScorecardLockedError } from '@/exception/scorecard';
import HttpStatusCode from '@/typings/httpStatusCode';
import {
  MatchResponse,
  Scorecard,
  ScorecardDisplayState,
  ScorecardResponse,
  ScorecardState,
  ScorecardStatus,
} from '@/typings/scorecard';
import { User } from '@/typings/user';
import { defineStore } from 'pinia';

export const useScorecardStore = defineStore({
  id: 'storeScorecard',
  state: (): ScorecardState => ({
    scorecards: [],
    scorecard: undefined,
    submitting: false,
  }),
  getters: {
    displayState(): ScorecardDisplayState {
      if (this.scorecard) {
        switch (this.scorecard.event.Status) {
          default:
          case ScorecardStatus.Scheduled:
            return ScorecardDisplayState.Preview;
          case ScorecardStatus.Open:
            return ScorecardDisplayState.Editable;
          case ScorecardStatus.Locked:
          case ScorecardStatus.Finalizing:
          case ScorecardStatus.Closed:
            return ScorecardDisplayState.View;
        }
      }
      return ScorecardDisplayState.Preview;
    },
    scorecardStatus(): string {
      // if (this.manage) {
      //   return 'Results Card';
      // }
      if (!this.scorecard) return '';
      const { event, usersAnswers } = this.scorecard;
      if (!event) {
        return '';
      }
      if (event.Status === 'Scheduled') {
        return '';
      }
      if (event.Status !== 'Open') {
        return (usersAnswers?.Score || 0).toString();
      }

      const statuses: string[] = [];

      // if (this.event.Briefcases.length) {
      //   statuses.push(this.briefcaseStatus());
      // }

      // if (this.event.Tables.length) {
      //   statuses.push(this.tableStatus());
      // }

      // if (this.event.Unscheduleds) {
      //   statuses.push(this.unscheduledStatus());
      // }

      // this.event.Matches.forEach((value) => {
      //   const status = this.matchStatus(value);
      //   statuses.push(status);
      // });

      /* eslint-disable no-param-reassign */
      const results = statuses.reduce(
        (prev, curr) => {
          switch (curr) {
            case 'Incomplete':
              prev.incomplete += 1;
              break;
            case 'Complete':
              prev.complete += 1;
              break;
            case 'In Progress':
              prev.inProgress += 1;
              break;
            default:
              break;
          }
          return prev;
        },
        {
          incomplete: 0,
          complete: 0,
          inProgress: 0,
        }
      );
      /* eslint-enable no-param-reassign */

      if (results.incomplete === 0 && results.inProgress === 0) {
        return 'Complete';
      }
      if (results.inProgress > 0 && results.incomplete >= 0) {
        return 'In Progress';
      }
      return 'Not Started';
    },
  },
  actions: {
    async getScorecards() {
      try {
        const response = await apiScorecards.getScorecards();
        this.scorecards = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getScorecardsByUser(userUuid: string) {
      try {
        const response = await apiScorecards.getScorecardsByUser(userUuid);
        return response.data
          .filter((x) => x.event.Status === 'Closed')
          .sort(
            (a, b) =>
              new Date(b.event.LocksAt).valueOf() -
              new Date(a.event.LocksAt).valueOf()
          );
      } catch (error) {
        console.error(error);
      }
    },
    async getScorecardBySlug(slug: Scorecard['Slug']) {
      try {
        const response = await apiScorecards.getScorecardBySlug(slug);
        this.scorecard = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getScorecardForUser(slug: Scorecard['Slug'], user: User['Name']) {
      try {
        const response = await apiScorecards.getScorecardForUser(slug, user);
        this.scorecard = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCorrectScorecard(slug: Scorecard['Slug']) {
      try {
        const response = await apiScorecards.getCorrectScorecard(slug);
        this.scorecard = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteScorecard(scorecard: Scorecard) {
      try {
        const response = await apiScorecards.deleteScorecard(scorecard.Slug);
        if (response.status === HttpStatusCode.NoContent) {
          this.scorecards = this.scorecards.filter(
            (s) => s.Slug !== scorecard.Slug
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveScorecard(
      scorecard: Scorecard,
      images: { cover?: File; twitter?: File }
    ) {
      try {
        if (scorecard.EventId) {
          await apiScorecards.postUpdateScorecard(scorecard, images);
        } else {
          await apiScorecards.postCreateScorecard(scorecard, images);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async setCurrentMatch(id: number) {
      try {
        if (this.scorecard)
          await apiScorecards.postSetCurrentMatch(
            this.scorecard.event.Slug,
            id
          );
      } catch (error) {
        console.error(error);
      }
    },
    async publishMatch(id: number, response: MatchResponse) {
      try {
        if (this.scorecard)
          return (
            await apiScorecards.postPublishMatch(
              this.scorecard.event.Slug,
              id,
              response
            )
          ).data;
      } catch (error) {
        console.error(error);
      }
      return 'SOMETHING WENT WRONG';
    },
    async updateMatchStatus(id: number, status: string) {
      try {
        if (this.scorecard)
          await apiScorecards.postMatchStatus(
            this.scorecard.event.Slug,
            id,
            status
          );
      } catch (error) {
        console.error(error);
      }
    },
    async setStatus(status: string) {
      try {
        if (this.scorecard)
          await apiScorecards.postSetStatus(this.scorecard.event.Slug, status);
      } catch (error) {
        console.error(error);
      }
    },

    async savePredictions(predictions: ScorecardResponse) {
      if (this.scorecard) {
        this.submitting = true;
        const response = await apiScorecards.postPredictions(
          this.scorecard.event.Slug,
          predictions
        );
        this.submitting = false;
        if (response.status === HttpStatusCode.Locked) {
          throw new ScorecardLockedError();
        }
      }
    },
    async fix(scorecard: Scorecard) {
      try {
        await apiScorecards.postFix(scorecard);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
