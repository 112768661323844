import { apiTemplates } from '@/api/templates';
import { Template, TemplateState } from '@/typings/template';
import { defineStore } from 'pinia';

export const useTemplateStore = defineStore({
  id: 'storeTemplates',
  state: (): TemplateState => ({
    templates: [],
  }),
  actions: {
    async getTemplates() {
      try {
        const response = await apiTemplates.getTemplates();
        this.templates = response.data;
      } catch (error) {
        console.error(error);
        this.templates = [];
      }
      return this.templates;
    },
    async addTemplate(template: Template) {
      try {
        const response = await apiTemplates.addTemplate(template);
        this.templates.push(response.data);
        return response;
      } catch (error) {
        console.error(error);
        this.templates = [];
      }
    },
    async editTemplate(template: Template) {
      try {
        const response = await apiTemplates.editTemplate(template);
        this.templates = this.templates.map((t) => {
          if (t.TemplateId === template.TemplateId) {
            return template;
          }
          return t;
        });
        // this.templates.push(response.data);
        return response;
      } catch (error) {
        console.error(error);
        this.templates = [];
      }
    },
    async deleteTemplate(template: Template) {
      try {
        const response = await apiTemplates.deleteTemplate(template);
        this.templates = this.templates.filter(
          (t) => t.TemplateId !== template.TemplateId
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
