import { api } from '@/api';
import { FactionPayload } from '@/typings/api/factions';
import { Faction, SeasonFilter } from '@/typings/faction';
import { Rank, User } from '@/typings/user';
import { AxiosPromise } from 'axios';

export const apiFactions = {
  postFaction(faction: FactionPayload): AxiosPromise {
    const formData = new FormData();

    for (const key in faction) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (faction as any)[key];
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          formData.append(`${key}[]`, data[i]);
        }
      } else {
        formData.append(key, data);
      }
    }

    return api.post('/faction', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getPublicFactions(): AxiosPromise<Faction[]> {
    return api.get('/faction/public');
  },
  getPrivateFactions(): AxiosPromise<Faction[]> {
    return api.get('/faction/private');
  },
  getFactionByUuid(uuid: string): AxiosPromise<Faction> {
    return api.get(`/faction/${uuid}`);
  },
  getRankedScorecards(uuid: string): AxiosPromise {
    return api.get(`/faction/${uuid}/scorecard/ranked`);
  },
  getScorecards(uuid: string): AxiosPromise {
    return api.get(`/faction/${uuid}/scorecard`);
  },
  getScorecard(uuid: string, slug: string): AxiosPromise {
    return api.get(`/faction/${uuid}/scorecard/${slug}`);
  },
  join(user: User, code: string): AxiosPromise {
    return api.post('/faction/join', {
      UserID: user.Name,
      JoinCode: code,
    });
  },
  leave(user: User, faction: Faction): AxiosPromise {
    return api.post(`/faction/${faction.Uuid}/leave`, {
      UserID: user.Name,
      GroupID: faction.Uuid,
    });
  },
  getChampions(uuid: string): AxiosPromise {
    return api.get(`/faction/${uuid}/champions`);
  },
  getMembers(uuid: string, pageSize?: number, page?: number): AxiosPromise {
    return api.get(
      `/faction/${uuid}/members?pageSize=${pageSize}&pageNumber=${page}`
    );
  },
  putFaction(faction: Faction): AxiosPromise {
    const formData = new FormData();

    for (const key in faction) {
      formData.append(key, (faction as unknown as Record<string, never>)[key]);
    }

    return api.put('/faction', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  downloadQrCode(faction: Faction): AxiosPromise {
    return api.get(`/faction/${faction.Uuid}/qr`, {
      responseType: 'arraybuffer',
    });
  },
  getFaction(uuid: string): AxiosPromise<Faction> {
    return api.get<Faction>(`/faction/${uuid}`);
  },
  putMemberRole(
    uuid: string,
    userUuid: string,
    rank: Rank
  ): AxiosPromise<unknown> {
    return api.put<unknown>(`/faction/${uuid}/member`, {
      Uuid: userUuid,
      Rank: rank,
    });
  },
  deleteMember(uuid: string, userUuid: string): AxiosPromise<unknown> {
    return api.delete<unknown>(`/faction/${uuid}/member/${userUuid}`);
  },
  getSeasons(uuid: string): AxiosPromise<SeasonFilter[]> {
    return api.get<SeasonFilter[]>(`/faction/${uuid}/scorecard/season`);
  },
  getScorecardsBySeason(uuid: string, season: number): AxiosPromise {
    return api.get(`/faction/${uuid}/scorecard/season/${season}`);
  },
};
