import axios from 'axios';
import { getCookie } from 'tiny-cookie';

export const api = axios.create({
  baseURL: process.env.VUE_APP_HOST + '/api',
  timeout: 30000,
});

api.interceptors.request.use(
  function (config) {
    if (config.headers) {
      const token = getCookie('pws-jwt');

      if (token != null) {
        config.headers.Authorization = `Token ${token}`;
      }

      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
