import { api } from '@/api';
import { Team, Wrestler } from '@/typings/wrestler';
import { AxiosPromise } from 'axios';

export const apiWrestlers = {
  getWrestlers(): AxiosPromise<Wrestler[]> {
    return api.get('/wrestler');
  },
  addWrestler(wrestler: Wrestler): AxiosPromise<Wrestler> {
    return api.post('/wrestler', wrestler);
  },
  editWrestler(wrestler: Wrestler): AxiosPromise<Wrestler> {
    return api.post(`/wrestler/${wrestler.SuperstarId}`, wrestler);
  },
  deleteWrestler(wrestler: Wrestler): AxiosPromise {
    return api.delete(`/wrestler/${wrestler.SuperstarId}`);
  },

  getTeams(): AxiosPromise<Team[]> {
    return api.get('/team');
  },
  addTeam(team: Team): AxiosPromise<Team> {
    return api.post('/team', team);
  },
  editTeam(team: Team): AxiosPromise<Team> {
    return api.post(`/team/${team.TeamId}`, team);
  },
  deleteTeam(team: Team): AxiosPromise {
    return api.delete(`/team/${team.TeamId}`);
  },
};
