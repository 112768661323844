<template>
  <div class="relative">
    <div
      class="flex items-center justify-center w-8 h-8 rounded-full bg-secondary-light-1"
    >
      <fa-icon icon="user" />
    </div>
    <slot />
  </div>
</template>
