import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Factions from '@/views/Factions/Factions.vue';
import Login from '@/views/Registration/Login.vue';
import Scorecards from '@/views/Scorecards/Scorecards.vue';
import Account from '@/views/Accounts/Account.vue';
import { getCookie } from 'tiny-cookie';
import { useUserStore } from '@/store/users';
import UserLink from '@/views/Twitter/UserLink.vue';
import MasterLink from '@/views/Twitter/MasterLink.vue';
import Scorecard from '@/views/Scorecards/Scorecard.vue';
import Archive from '@/views/Scorecards/Archive.vue';
import ScorecardEditor from '@/views/Scorecards/ScorecardEditor.vue';
import ForgottenPassword from '@/views/Registration/ForgottenPassword.vue';
import Registration from '@/views/Registration/Registration.vue';
import LoginVue from '@/views/Twitter/Login.vue';
import Signup from '@/views/Twitter/Signup.vue';
import Faction from '@/views/Factions/Faction.vue';
import FactionInvite from '@/views/Factions/FactionInvite.vue';
import BrandInvite from '@/views/Factions/BrandInvite.vue';
import FactionScorecard from '@/views/Factions/FactionScorecard.vue';
import DataManagement from '@/views/DataManagement/Index.vue';
import SeasonsVue from '@/views/DataManagement/Seasons.vue';
import TemplatesVue from '@/views/DataManagement/Templates.vue';
import BrandsVue from '@/views/DataManagement/Brands.vue';
import WrestlersVue from '@/views/DataManagement/Wrestlers.vue';
import TeamsVue from '@/views/DataManagement/Teams.vue';
import UsersVue from '@/views/DataManagement/Users.vue';
import SocialsVue from '@/views/DataManagement/Socials.vue';
import SystemVue from '@/views/DataManagement/System.vue';
import ResetPasswordVue from '@/views/Registration/ResetPassword.vue';
import TwitterWarning from '@/views/Twitter/EmailWarning.vue';
import { User } from '@/typings/user';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Scorecards,
    meta: {
      requiresAuth: false,
      isAdmin: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/forgotten-password',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    props: true,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordVue,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Registration,
    props: true,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/scorecards',
    name: 'Scorecards',
    component: Scorecards,
    meta: {
      requiresAuth: false,
      isAdmin: false,
    },
  },
  {
    path: '/archive',
    name: 'Archive',
    component: Archive,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/factions',
    name: 'Factions',
    component: Factions,
    meta: {
      requiresAuth: true,
      isAdmin: false,
    },
  },
  {
    path: '/faction/:factionId',
    name: 'Faction',
    component: Faction,
    meta: {
      requiresAuth: true,
      isAdmin: false,
    },
  },
  {
    path: '/faction/:factionId/scorecard/:slug',
    name: 'FactionScorecard',
    component: FactionScorecard,
    meta: {
      requiresAuth: true,
      isAdmin: false,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
      isAdmin: false,
    },
  },
  {
    path: '/account/:user',
    name: 'AccountUser',
    component: Account,
    meta: {
      requiresAuth: true,
      isAdmin: false,
    },
  },
  {
    path: '/auth/twitter/link',
    name: 'Link User Twitter Account',
    component: UserLink,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/auth/twitter/master',
    name: 'Link Master Twitter Account',
    component: MasterLink,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/auth/twitter/login',
    name: 'TwitterLogin',
    component: LoginVue,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/auth/twitter/signup',
    name: 'TwitterSignup',
    component: Signup,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
  {
    path: '/card/results/:slug',
    name: 'ScorecardManage',
    component: Scorecard,
    meta: {
      isAdmin: true,
      requiresAuth: true,
    },
  },
  {
    path: '/card/:slug',
    name: 'Scorecard',
    component: Scorecard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/card/:slug/:user',
    name: 'ScorecardUser',
    component: Scorecard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/data',
    name: 'DataManagement',
    component: DataManagement,
    children: [
      { path: 'seasons', name: 'Seasons', component: SeasonsVue },
      { path: 'presets', name: 'Presets', component: TemplatesVue },
      { path: 'brands', name: 'Brands', component: BrandsVue },
      { path: 'wrestlers', name: 'Wrestlers', component: WrestlersVue },
      { path: 'teams', name: 'Teams', component: TeamsVue },
      { path: 'users', name: 'Users', component: UsersVue },
      { path: 'socials', name: 'Socials', component: SocialsVue },
      { path: 'system', name: 'System', component: SystemVue },
    ],
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/card/editor',
    name: 'ScorecardEditor',
    component: ScorecardEditor,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/card/editor/:slug',
    name: 'ScorecardExistingEditor',
    component: ScorecardEditor,
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/invite/f/:uuid/:code',
    name: 'FactionInvite',
    component: FactionInvite,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/invite/b/:uuid/',
    name: 'BrandInvite',
    component: BrandInvite,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/twitter-unsupport',
    name: 'TwitterWarning',
    component: TwitterWarning,
    props: false,
    meta: {
      requiresAuth: false,
      isAdmin: false,
      passOver: true,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

function requiresEmail(user: User) {
  return (
    user.Email === '' &&
    user.TwitterHandle &&
    user.TwitterHandle.length > 0 &&
    getCookie('twitter-warning') !== 'true'
  );
}

router.beforeEach(async (to, from, next) => {
  const passThroughRoute = to.fullPath;
  const userStore = useUserStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (getCookie('pws-jwt') == null) {
      next({
        name: 'Login',
        params: { nextUrl: passThroughRoute },
      });
    } else {
      await userStore.getLoggedInUser();
      const { user } = userStore;
      if (user === null) {
        next({
          name: 'Login',
          params: { nextUrl: passThroughRoute },
        });
      } else {
        if (to.matched.some((record) => record.meta.isAdmin)) {
          if (user?.PrivilegeId === 1) {
            next();
          } else {
            next();
          }
        } else {
          next();
        }
      }
    }
  } else if (
    getCookie('pws-jwt') !== null &&
    !to.matched.some((record) => record.meta.passOver)
  ) {
    await userStore.getLoggedInUser();
    if (userStore.user === null) {
      next({
        name: 'Login',
        params: { nextUrl: passThroughRoute },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
