import { api } from '@/api';
import { Tag, User } from '@/typings/user';
import { AxiosPromise } from 'axios';

export const apiTags = {
  getTags(): AxiosPromise<Tag[]> {
    return api.get('/tag');
  },
  addTag(tag: Tag): AxiosPromise<Tag> {
    return api.post('/tag', tag);
  },
  editTag(tag: Tag): AxiosPromise<Tag> {
    return api.post(`/tag/${tag.Id}`, tag);
  },
  deleteTag(tag: Tag): AxiosPromise {
    return api.delete(`/tag/${tag.Id}`);
  },

  addTagToUser(user: User, tag: Tag): AxiosPromise {
    return api.post(`/user/${user.Uuid}/tag/${tag.Id}`);
  },
  deleteTagFromUser(user: User, tag: Tag): AxiosPromise {
    return api.delete(`/user/${user.Uuid}/tag/${tag.Id}`);
  },
};
