import { api } from '@/api';
import { Template } from '@/typings/template';
import { AxiosPromise } from 'axios';

export const apiTemplates = {
  getTemplates(): AxiosPromise<Template[]> {
    return api.get('/template');
  },
  addTemplate(template: Template): AxiosPromise<Template> {
    return api.post('/template', template);
  },
  editTemplate(template: Template): AxiosPromise<Template> {
    return api.post(`/template/${template.TemplateId}`, template);
  },
  deleteTemplate(template: Template): AxiosPromise {
    return api.delete(`/template/${template.TemplateId}`);
  },
};
