import { apiBrands } from '@/api/brand';
import { Brand, BrandState } from '@/typings/brand';
import { defineStore } from 'pinia';

export const useBrandStore = defineStore({
  id: 'storeBrand',
  state: (): BrandState => ({
    brands: [],
  }),
  actions: {
    async getBrands() {
      try {
        const response = await apiBrands.getBrands();
        this.brands = response.data;
      } catch (error) {
        console.error(error);
        this.brands = [];
      }
      return this.brands;
    },
    async addBrand(brand: Brand) {
      try {
        const response = await apiBrands.addBrand(brand);
        this.brands.push(response.data);
        return response;
      } catch (error) {
        console.error(error);
        this.brands = [];
      }
    },
    async editBrand(brand: Brand) {
      try {
        const response = await apiBrands.editBrand(brand);
        this.brands = this.brands.map((t) => {
          if (t.CompanyId === brand.CompanyId) {
            return brand;
          }
          return t;
        });
        // this.brands.push(response.data);
        return response;
      } catch (error) {
        console.error(error);
        this.brands = [];
      }
    },
    async deleteBrand(brand: Brand) {
      try {
        const response = await apiBrands.deleteBrand(brand);
        this.brands = this.brands.filter(
          (t) => t.CompanyId !== brand.CompanyId
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
