import { api } from '@/api';
import { Season } from '@/typings/season';
import { AxiosPromise } from 'axios';

export const apiSeasons = {
  getSeasons(): AxiosPromise<Season[]> {
    return api.get('/season');
  },
  getCurrentSeason(): AxiosPromise<Season> {
    return api.get('/season/current');
  },
  setCurrentSeason(id: Season['SeasonId']): AxiosPromise<Season> {
    return api.post(`/season/${id}/current`);
  },
  postSeason(payload: Partial<Season>): AxiosPromise<Season> {
    return api.post('/season', payload);
  },
  updateSeason(payload: Season): AxiosPromise<Season> {
    return api.post(`/season/${payload.SeasonId}`, payload);
  },
};
