import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faEye,
  faPlus,
  faDoorOpen,
  faQrcode,
  faCog,
  faTimes,
  faPencilAlt,
  faTrash,
  faMinus,
  faDownload,
  faEnvelope,
  faLink,
  faPlay,
  faBackward,
  faStepBackward,
  faForward,
  faStepForward,
  faBan,
  faPencilRuler,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VCalendar from 'v-calendar';
import vSelect from 'vue-select';
import SocketIO from 'socket.io-client';

import PrimeVue from 'primevue/config';

import Spinner from '@/components/UI/Spinner.vue';
import Button from '@/components/UI/Button.vue';

import './styles/tailwind.css';
import 'vue3-date-time-picker/dist/main.css';
import 'v-calendar/dist/style.css';
import 'vue-select/dist/vue-select.css';
import './styles/tabs.scss';

const debugMode = process.env.NODE_ENV === 'development';

const app = createApp(App);
app.config.performance = debugMode;
app.use(PrimeVue);

const socketConnection = SocketIO(process.env.VUE_APP_HOST);

app.config.globalProperties.$socket = socketConnection;

app.provide('$socket', app.config.globalProperties.$socket);

library.add(
  faUser,
  faEye,
  faPlus,
  faDoorOpen,
  faCog,
  faTimes,
  faPencilAlt,
  faTrash,
  faMinus,
  faDownload,
  faEnvelope,
  faLink,
  faBackward,
  faStepBackward,
  faForward,
  faStepForward,
  faPlay,
  faBan,
  faPencilRuler,
  faQrcode
);

app.use(VCalendar);
app.use(router);
app.use(createPinia());

app.component('fa-icon', FontAwesomeIcon);
app.component('v-select', vSelect);
app.component('Spinner', Spinner);
app.component('Button', Button);

app.mount('#app');
