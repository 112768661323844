import { api } from '@/api';
import {
  Statistics,
  UpdateEmailPreferences,
  UpdatePassword,
  User,
  UserRegistration,
  UserRegistrationResponse,
  VerifiedUserResponse,
} from '@/typings/user';
import { AxiosPromise } from 'axios';

export const apiUsers = {
  login(email: string, password: string): AxiosPromise<string> {
    return api.post('/user/login', {
      email,
      password,
    });
  },
  getUser(): AxiosPromise<VerifiedUserResponse> {
    return api.get('/user/verify');
  },
  getSecureUser(): AxiosPromise<User> {
    return api.get('/user/me');
  },
  getStatistics(id: string): AxiosPromise<Statistics> {
    return api.get(`/user/${id}/statistics`);
  },
  changePassword(data: UpdatePassword): AxiosPromise {
    return api.post('/user/me/password', data);
  },
  updateEmailPreferences(data: UpdateEmailPreferences): AxiosPromise {
    return api.post('/user/me', data);
  },
  linkTwitterAccount(): AxiosPromise {
    return api.get('/twitter/user');
  },
  unlinkTwitterAccount(id: User['UserId']): AxiosPromise {
    return api.post('/twitter/user/unlink', { ID: id });
  },
  linkTwitterAccountCallback(data: unknown): AxiosPromise {
    return api.post('/twitter/user/callback', data);
  },
  loginTwitterCallback(data: unknown): AxiosPromise {
    return api.post('/twitter/login/callback', data);
  },
  signupTwitterCallback(data: unknown): AxiosPromise {
    return api.post('/twitter/signup/callback', data);
  },
  forgetPassword(email: string): AxiosPromise {
    return api.post('/user/password/forget', { Email: email });
  },
  forgetPasswordByUuid(uuid: string): AxiosPromise {
    return api.post(`/user/${uuid}/password/forget`);
  },
  registerNewUser(
    data: UserRegistration
  ): AxiosPromise<UserRegistrationResponse> {
    return api.post('/user/signup', data);
  },
  twitterLogin(): AxiosPromise {
    return api.get('/twitter/login');
  },
  twitterSignup(): AxiosPromise {
    return api.get('/twitter/signup');
  },
  getViewUser(user: string): AxiosPromise {
    return api.get(`/user/view/${user}`);
  },
  getUsers(): AxiosPromise {
    return api.get('/user');
  },
  resetPassword(payload: {
    Link: string;
    Password: string;
    ConfirmPassword: string;
  }): AxiosPromise {
    return api.post('/user/password/reset', payload);
  },
};
