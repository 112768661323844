export enum TemplateType {
  YesNo = 'Yes/No',
  Number = 'Number',
  OverUnder = 'OverUnder',
  MultipleChoice = 'Multi',
  FreeText = 'Text',
  Participants = 'Wrestler',
  AnyWrestler = 'AllWrestlers',
  Falls = 'Fall',
  NoDQFalls = 'FallNDQ',
  CageFalls = 'CageFall',
  Match = 'Match',
}

export type Template = {
  TemplateId?: number;
  Text: string;
  Type: TemplateType;
  SocialFormat: string;
  UserNotes: string;
  Options: string[];
};

export type TemplateState = {
  templates: Template[];
};
