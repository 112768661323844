import { apiSystem } from '@/api/system';
import { SystemConfiguration, SystemState } from '@/typings/system';
import { defineStore } from 'pinia';

export const useSystemStore = defineStore({
  id: 'storeSystem',
  state: (): SystemState => ({
    tweets: [],
    configuration: undefined,
    status: '',
  }),
  actions: {
    async getRecentTweets() {
      try {
        const response = await apiSystem.getTweetLog();
        this.tweets = response.data;
      } catch (error) {
        console.error(error);
        this.tweets = [];
      }
      return this.tweets;
    },
    async getConfiguration() {
      try {
        const response = await apiSystem.getSystemConfiguration();
        this.configuration = response.data;
        this.status = this.configuration.SystemStatus;
      } catch (error) {
        console.error(error);
        this.configuration = {} as SystemConfiguration;
      }
      return this.configuration;
    },
    async unlinkTwitterAccount() {
      if (this.configuration) {
        try {
          await apiSystem.unlinkTwitterAccount();
          this.configuration.GlobalTweetId = '';
        } catch (error) {
          console.error(error);
        }
      }
      return this.configuration;
    },
    async linkTwitterAccount() {
      try {
        const response = await apiSystem.linkTwitterAccount();
        return response.data;
      } catch (error) {
        console.error(error);
      }
      return this.configuration;
    },
    async linkTwitterCallback(data: unknown) {
      try {
        const response = await apiSystem.linkTwitterCallback(data);
        return response.data;
      } catch (error) {
        console.error(error);
      }
      return this.configuration;
    },
    async saveTwitterConfiguration() {
      if (this.configuration) {
        try {
          const response = await apiSystem.saveTwitterConfiguration(
            this.configuration
          );
          return response.data;
        } catch (error) {
          console.error(error);
        }
      }
      return this.configuration;
    },
    async saveStatus() {
      if (this.configuration) {
        try {
          const response = await apiSystem.saveStatus(this.configuration);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      }
      return this.configuration;
    },
  },
});
