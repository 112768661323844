import { api } from '@/api';
import {
  MatchResponse,
  Scorecard,
  ScorecardResponse,
  UserScorecard,
} from '@/typings/scorecard';
import { AxiosPromise } from 'axios';

export const apiScorecards = {
  getScorecards(): AxiosPromise {
    return api.get('/scorecard');
  },
  getScorecardsByUser(userUuid: string): AxiosPromise<UserScorecard[]> {
    return api.get(`/scorecard/user/${userUuid}`);
  },
  getScorecardBySlug(slug: string): AxiosPromise<UserScorecard> {
    return api.get(`/scorecard/${slug}`);
  },
  getScorecardForUser(slug: string, user: string): AxiosPromise {
    return api.get(`/scorecard/${slug}/${user}`);
  },
  getCorrectScorecard(slug: string): AxiosPromise {
    return api.get(`/scorecard/correct/${slug}`);
  },
  deleteScorecard(slug: string): AxiosPromise {
    return api.delete(`/scorecard/${slug}`);
  },
  postCreateScorecard(
    scorecard: Scorecard,
    images: { cover?: File; twitter?: File }
  ): AxiosPromise {
    const formData = new FormData();

    formData.append('Scorecard', JSON.stringify(scorecard));
    if (images.cover) formData.append('CoverImage', images.cover);
    if (images.twitter) formData.append('TwitterImage', images.twitter);

    return api.post('/scorecard', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  postUpdateScorecard(
    scorecard: Scorecard,
    images: { cover?: File; twitter?: File }
  ): AxiosPromise {
    const formData = new FormData();

    formData.append('Scorecard', JSON.stringify(scorecard));
    if (images.cover) formData.append('CoverImage', images.cover);
    if (images.twitter) formData.append('TwitterImage', images.twitter);

    return api.post(`/scorecard/${scorecard.Slug}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  postSetCurrentMatch(slug: string, id: number): AxiosPromise {
    return api.post(`/scorecard/${slug}/current_match`, {
      MatchId: id,
    });
  },
  postSetStatus(slug: string, status: string): AxiosPromise {
    return api.post(`/scorecard/${slug}/current_status`, {
      Status: status,
    });
  },
  postPublishMatch(
    slug: string,
    id: number,
    response: MatchResponse
  ): AxiosPromise {
    return api.post(`/scorecard/${slug}/match/${id}/tweet`, response);
  },
  postMatchStatus(slug: string, id: number, status: string): AxiosPromise {
    return api.post(`/scorecard/${slug}/match/${id}/current_status`, {
      Status: status,
    });
  },
  postPredictions(slug: string, predictions: ScorecardResponse): AxiosPromise {
    return api.post(
      `/scorecard/${slug}/submit`,
      {
        response: predictions,
      },
      {
        validateStatus: (status: number) => status < 430,
      }
    );
  },
  postRunScoring(slug: string): AxiosPromise {
    return api.post(`/scorecard/${slug}/scoring`);
  },
  getResults(slug: string): AxiosPromise {
    return api.get(`/scorecard/data/${slug}`, {
      responseType: 'blob',
    });
  },
  postFix({ Slug: slug }: Scorecard): AxiosPromise {
    return api.post(`/scorecard/${slug}/fix`);
  },
};
