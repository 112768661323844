import { Scorecard } from './scorecard';
import { Rank, User } from './user';

export type Faction = {
  GroupId: number;
  Name: string;
  Privacy: number;
  MemberCount: number;
  EventCount: number;
  Joined: boolean;
  Uuid: string;
  Description: string;
  Admin: string;
  Champions: FactionReigningChampions[];
  JoinCode: string;
  Image?: File;
  ImageName: string;
  FactionCards?: Scorecard[];
  UserRank: Rank;
  EntryEnds?: string;
  ClosedAt?: string;
};

export type FactionState = {
  publicFactions: Faction[];
  privateFactions: Faction[];
  faction: Faction | null;
};

export enum FactionPrivacy {
  InviteOnly = 0,
  Public = 1,
  Private = 2,
}

export type FactionComposable = {
  joinWithCode: (faction: Faction, code?: string) => Promise<boolean>;
  join: (faction: Faction) => Promise<boolean>;
};

export type FactionBrandStatistics = {
  Rankings: FactionStatistic[];
  Brand: string;
};

export type FactionChampions = {
  Champions: FactionStatistic[];
  Brand: string;
};

export type FactionStatistic = {
  User: User;
  Reigns: number;
  Wins: number;
};

export type FactionScorecardRank = {
  User: User;
  Score: number;
  Rank: number;
};

export interface FactionScorecard extends Scorecard {
  Rankings: FactionScorecardRank[];
}

export interface FactionReigningChampions {
  Champions: FactionReigningChampion;
  Brand: string;
}

export type FactionReigningChampion = {
  Champion: string;
  EventName: string;
  Streak: number;
};

export type SeasonFilter = {
  name: string;
  seasonid: number;
};
