import { apiTags } from '@/api/tag';
import { Tag, TagState, User } from '@/typings/user';
import { AxiosError } from 'axios';
import { defineStore } from 'pinia';

export const useTagStore = defineStore({
  id: 'storeTags',
  state: (): TagState => ({
    tags: [],
  }),
  actions: {
    async getTags() {
      try {
        const response = await apiTags.getTags();
        this.tags = response.data;
      } catch (error) {
        console.error(error);
        this.tags = [];
      }
      return this.tags;
    },
    async addTag(tag: Tag) {
      try {
        const response = await apiTags.addTag(tag);
        this.tags.push(response.data);
        return response;
      } catch (error) {
        return (error as AxiosError).response;
      }
    },
    async editTag(tag: Tag) {
      try {
        const response = await apiTags.editTag(tag);
        this.tags = this.tags.map((t) => {
          if (t.Id === tag.Id) {
            return tag;
          }
          return t;
        });
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteTag(tag: Tag) {
      try {
        const response = await apiTags.deleteTag(tag);
        this.tags = this.tags.filter((t) => t.Id !== tag.Id);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    async addTagToUser(user: User, tag: Tag) {
      try {
        const response = await apiTags.addTagToUser(user, tag);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteTagFromUser(user: User, tag: Tag) {
      try {
        const response = await apiTags.deleteTagFromUser(user, tag);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
