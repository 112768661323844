export enum NotificationType {
  ScoreUpdate = 'score_update',
}

export enum NotificationActions {
  View = 'view',
}

type Notification = {
  sentAt: number;
  title: string;
  body: string;
  actions: Array<NotificationActions>;
};

export type ScoreUpdatedNotification = {
  type: NotificationType.ScoreUpdate;
  meta: {
    slug: string;
  };
} & Notification;

export type Notifications = ScoreUpdatedNotification;
