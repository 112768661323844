import { apiWrestlers } from '@/api/wrestler';
import { Team, Wrestler, WrestlerState } from '@/typings/wrestler';
import { defineStore } from 'pinia';

export const useWrestlerStore = defineStore({
  id: 'storeWrestler',
  state: (): WrestlerState => ({
    wrestlers: [],
    teams: [],
  }),
  getters: {
    wrestlerSearchOptions: (state) =>
      state.wrestlers.map((w) => ({
        value: w.SuperstarId,
        text: w.Name,
      })),
  },
  actions: {
    async getWrestlers() {
      try {
        const response = await apiWrestlers.getWrestlers();
        this.wrestlers = response.data.sort((a, b) =>
          a.Name > b.Name ? 1 : -1
        );
      } catch (error) {
        console.error(error);
      }
    },
    async addWrestler(wrestlers: Wrestler) {
      try {
        const response = await apiWrestlers.addWrestler(wrestlers);
        this.wrestlers.push(response.data);
        return response;
      } catch (error) {
        console.error(error);
        this.wrestlers = [];
      }
    },
    async editWrestler(wrestlers: Wrestler) {
      try {
        const response = await apiWrestlers.editWrestler(wrestlers);
        this.wrestlers = this.wrestlers.map((t) => {
          if (t.SuperstarId === wrestlers.SuperstarId) {
            return wrestlers;
          }
          return t;
        });
        return response;
      } catch (error) {
        console.error(error);
        this.wrestlers = [];
      }
    },
    async deleteWrestler(wrestlers: Wrestler) {
      try {
        const response = await apiWrestlers.deleteWrestler(wrestlers);
        this.wrestlers = this.wrestlers.filter(
          (t) => t.SuperstarId !== wrestlers.SuperstarId
        );
        return response;
      } catch (error) {
        console.error(error);
      }
    },

    async getTeams() {
      try {
        const response = await apiWrestlers.getTeams();
        this.teams = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async addTeam(teams: Team) {
      try {
        const response = await apiWrestlers.addTeam(teams);
        this.teams.push(response.data);
        return response;
      } catch (error) {
        console.error(error);
        this.teams = [];
      }
    },
    async editTeam(teams: Team) {
      try {
        const response = await apiWrestlers.editTeam(teams);
        this.teams = this.teams.map((t) => {
          if (t.TeamId === teams.TeamId) {
            return teams;
          }
          return t;
        });
        return response;
      } catch (error) {
        console.error(error);
        this.teams = [];
      }
    },
    async deleteTeam(teams: Team) {
      try {
        const response = await apiWrestlers.deleteTeam(teams);
        this.teams = this.teams.filter((t) => t.TeamId !== teams.TeamId);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
