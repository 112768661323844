import { api } from '@/api';
import { SystemConfiguration, Tweet } from '@/typings/system';
import { AxiosPromise } from 'axios';

export const apiSystem = {
  getTweetLog(): AxiosPromise<Tweet[]> {
    return api.get('/system/tweet');
  },
  getSystemConfiguration(): AxiosPromise<SystemConfiguration> {
    return api.get('/system/configuration');
  },
  linkTwitterAccount(): AxiosPromise {
    return api.get('/twitter/master');
  },
  unlinkTwitterAccount(): AxiosPromise {
    return api.post('/twitter/master/unlink');
  },
  linkTwitterCallback(data: unknown): AxiosPromise {
    return api.post('/twitter/master/callback', data);
  },
  saveTwitterConfiguration(data: SystemConfiguration): AxiosPromise {
    return api.post('/system/tweet/configuration', data);
  },
  saveStatus(data: SystemConfiguration): AxiosPromise {
    return api.post('/system/status', {
      status: data.SystemStatus,
    });
  },
};
