import { api } from '@/api';
import { Brand } from '@/typings/brand';
import { AxiosPromise } from 'axios';

export const apiBrands = {
  getBrands(): AxiosPromise<Brand[]> {
    return api.get<Brand[]>('/brand');
  },
  addBrand(brand: Brand): AxiosPromise<Brand> {
    return api.post('/brand', brand);
  },
  editBrand(brand: Brand): AxiosPromise<Brand> {
    return api.post(`/brand/${brand.CompanyId}`, brand);
  },
  deleteBrand(brand: Brand): AxiosPromise {
    return api.delete(`/brand/${brand.CompanyId}`);
  },
  downloadQrCode(brand: Brand): AxiosPromise {
    return api.get(`/brand/${brand.CompanyId}/qr`, {
      responseType: 'arraybuffer',
    });
  },
};
