export enum MatchWinnerType {
  Participating = 'Participating',
  AllWrestlers = 'All',
}

export enum ScorecardDisplayState {
  Preview,
  View,
  Editable,
}

export enum ScorecardStatus {
  Open = 'Open',
  Locked = 'Locked',
  Scheduled = 'Scheduled',
  Finalizing = 'Finalising',
  Closed = 'Closed',
}

export enum ScorecardViewMode {
  Public,
  User,
  Results,
}

export type AnswerResponse = string | number | Array<string | number>;

export type ScorecardState = {
  scorecards: Scorecard[];
  scorecard?: UserScorecard;
  submitting: boolean;
};

export type Scorecard = {
  EventId: number;
  Title: string;
  Unscheduleds: number;
  Tables: string[];
  Brand: string;
  UserID: number;
  Locked: string;
  Deleted: string;
  Status: ScorecardStatus;
  Scheduled: Array<number | string>;
  CurrentMatch: string;
  Briefcases: string[];
  SocialHashTags: string;
  BriefcasePointValue: number;
  UnscheduledPointValue: number;
  AllUnscheduledBonusValue: number;
  TablePointValue: number;
  ClosingWarningSent: string;
  PossibleScore: number;
  OpensAt: string;
  LocksAt: string;
  ClosedAt: string;
  Slug: string;
  MaxScore: number;
  Matches: Match[];
  IsChampionshipEligible?: boolean;
  CoverImage?: string;
  TwitterImage?: string;
};

export type Briefcases = string[];
export type Tables = string[];
export type Unscheduled = Array<string | number>;

export type ScorecardResponse = {
  Score: number;
  Matches: MatchResponse[];
  Tables: Tables;
  Briefcases: Briefcases;
  Unscheduled: Unscheduled;
  Correct: boolean;
};

export type UserScorecard = {
  event: Scorecard;
  usersAnswers: ScorecardResponse;
  correctAnswers: ScorecardResponse;
};

export type MatchParticipant = {
  SuperstarId?: number;
  TeamId?: number;
  ExtraText: string;
};

export enum MatchStatus {
  Void = 'Void',
  Open = 'Open',
}

export type Match = {
  MatchId?: number;
  Title: string;
  Subtitle: string;
  ThirdTitle: string;
  Status: MatchStatus;
  Deleted: boolean;
  WinnerPointValue: number;
  ExtraPointValue: number;
  WinnerType: string;
  SocialWinnerFormat: string;
  SocialMatchName: string;
  HasWinner: boolean;
  Participants: MatchParticipant[];
  Teams: MatchParticipant[];
  Questions: Extra[];
  Order: number;
};

export type Extra = {
  QuestionId?: number;
  Text: string;
  Type: string;
  Options: string[];
  SocialFormat: string;
  UserNotes: string;
  BaseValue: number;
  Order: number;
  Deleted: boolean;
};

export type ExtraResponse = {
  QuestionId: number;
  Answer: AnswerResponse;
  State: number;
};

export type MatchResponse = {
  MatchId: number;
  Answer: AnswerResponse;
  Questions: ExtraResponse[];
  State: number;
  Score: number;
};
