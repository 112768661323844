import { AnswerResponse } from '@/typings/scorecard';
import { ValidationComposable } from '@/typings/validations';

export const useValidation = (): ValidationComposable => {
  const isPositiveNumber = (value: AnswerResponse) =>
    !!value &&
    value >= 0 &&
    value.toString().length > 0 &&
    !isNaN(Number(value));
  return {
    isPositiveNumber,
  };
};
