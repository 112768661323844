export type EmailPreference = {
  Uuid?: string;
  ScorecardOpened: boolean;
  ScorecardLockingSoon: boolean;
  Brands: Record<string, boolean>;
};

export type Tag = {
  BackgroundColor: string;
  Id?: number;
  Text: string;
  TextColor: string;
  Uuid?: string;
};

export type User = {
  CreatedAt: string;
  Email: string | null;
  EmailPreference: EmailPreference;
  Name: string;
  PrivilegeId: number;
  Tags: Tag[];
  TwitterHandle: string | null;
  Uuid: string;
  UserId: number;
};

export enum Rank {
  owner = 1,
  admin = 2,
  moderator = 3,
  member = 99,
}

export type FactionMember = {
  IsFollower: boolean;
  IsMember: boolean;
  Rank: Rank;
  Users: User[];
};

export interface FactionUser extends User {
  FactionMember: FactionMember;
}

export type UserState = {
  user: User | null;
  viewUser: User | null;
  secureUser: User | null;
  token: string;
  validationError: string;
};

export type TagState = {
  tags: Tag[];
};

export type VerifiedUserResponse = {
  verified: boolean;
  user: User;
};

export type SecureUserResponse = {
  UserId: number;
  Uuid: string;
  Name: string;
  Email: string;
  CreatedAt: string;
  PrivilegeId: string;
  TwitterHandle: string;
  TwitterId: string;
};

export type Statistics = {
  Pinned: [];
  Unpinned: [];
};

export type UpdatePassword = {
  current: string;
  new: string;
  confirm: string;
  error: boolean;
};

export type UpdateEmailPreferences = {
  Email: string | null;
  TwitterHandle: string | null;
  EmailPreferences: EmailPreference;
};

export type UserRegistration = {
  email: string;
  emailPreferences: EmailPreference;
  username: string;
  password: string;
  checkPassword: string;
};

export type UserRegistrationResponse = {
  userCreated: boolean;
  emailError?: string;
  userError?: string;
  passwordError?: string;
};
