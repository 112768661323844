import { apiSeasons } from '@/api/season';
import { Season, SeasonState } from '@/typings/season';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

export const useSeasonStore = defineStore({
  id: 'storeSeason',
  state: (): SeasonState => ({
    seasons: [],
    current: undefined,
  }),
  actions: {
    async getSeasons() {
      try {
        const response = await apiSeasons.getSeasons();
        this.seasons = response.data.reverse();
      } catch (error) {
        console.error(error);
        this.seasons = [];
      }
      return this.seasons;
    },
    async getCurrentSeason() {
      try {
        const response = await apiSeasons.getCurrentSeason();
        this.current = response.data;
      } catch (error) {
        console.error(error);
        this.current = undefined;
      }
      return this.current;
    },
    async setCurrentSeason(season: Season) {
      try {
        if (season.SeasonId) {
          await apiSeasons.setCurrentSeason(season.SeasonId);
          this.current = cloneDeep(season);
        }
      } catch (error) {
        console.error(error);
        this.current = undefined;
      }
      return this.current;
    },
  },
});
